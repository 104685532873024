const API_KEY = {
	// "USER_SERVER": "http://window.100tp.cn:9100/urm", // 用户管理系统
	"USER_SERVER": "/userApi", // 用户管理系统
	
	// 用户相关
	"USER_LOGIN": "/userPhoneLogin", // 用户登录
	"RESET_PWD": "/modifyPassword", // 重置密码表单提交接口

	// "BASE_SERVER": "http://window.100tp.cn:8780/bdp", // 基础平台
	"BASE_SERVER": "/bdpApi", // 基础平台
	"IMAGE_READ_SERVER": "http://pics.xupu360.com:9000", // 图片阅读服务器
	"IMAGE_SERVER": "http://image.xupu360.com:9280", // 图片管理服务器http://image.xupu360.com:9280/imp http://192.168.0.105:8080/imp

	"ADMIN_SERVER_ORIGINAL": "http://window.100tp.cn:8880/fmp", // 家族管理后台
	// "ADMIN_SERVER": "http://window.100tp.cn:8880/fmp", // 家族管理后台——代理
	"ADMIN_SERVER": "/api", // 家族管理后台

	// "HUMAN_SERVER": "http://window.100tp.cn:8680/hmp", // 百通谱后台
	"HUMAN_SERVER": "/humanApi", // 百通谱后台

	// 业务模块相关
	"UPLOAD_IMAGE": "/imp/upload/uploadBDPImage", // 默认图片上传——也就是所有图片上传功能，都可以用这个接口，只有一个参数，参数名是moduleName
	// 其他公用模块
	// 姓氏——BDP
	"SURNAME_ALL": "/surnamemanage/findAll", // 所有
	// 生僻字——BDP
	"RARELYUSE_ALL": "/rarelyusedword/findAll", // 所有

	// 家族相关
	"FAMILY_USER": "/findFamilyListByUserId", // 根据用户id查询所属家族列表
	"FAMILY_DETAIL": "/familyinfomanage/findById", // 查看指定家族信息
	"FAMILY_SETTING": "/familysetting/findFamilySettingByFamilyId", // 家族设置相关
	// 家族分支
	"FAMILY_BRANCH": "/subfamilyinfo/findByPage", // 家族分支列表
	"FAMILY_BRANCH_ALL": "/subfamilyinfo/findAll", // 家族分支列表
	"FAMILY_BRANCH_ADD": "/subfamilyinfo/addSubFamilyInfo", // 添加家族分支
	"FAMILY_BRANCH_MULTY_ADD": "/subfamilyinfo/batchAddSubFamilyInfo", // 批量添加家族分支
	"FAMILY_BRANCH_MEMBER_SUBCLAN": "/subfamilyinfo/batchUpdateMembersSubFamilyInfo", // 批量更新家庭成员所属分支
	"FAMILY_COUNCIL": "/familycouncil/findFamilyCouncilListByFamilyId",    //查询理事会成员列表
	"FAMILY_COUNCIL_ADD":"/familycouncil/add",    //添加理事会成员
	"FAMILY_COUNCIL_EDIT":"/familycouncil/update",    //添加理事会成员
	"FAMILY_COUNCIL_DELETE":"/familycouncil/deleteById",    //添加理事会成员
	// 家族优惠
	"FAMILY_DISCOUNT":"/discountsstaff/findDiscountsStaffListBySettingId",    //查询优惠列表
	"FAMILY_DISCOUNT_ADD":"/discountsstaff/add",    //增加优惠列表
	"FAMILY_DISCOUNT_EDIT":"/discountsstaff/update",    //修改优惠列表
	"FAMILY_DISCOUNT_DELETE":"/discountsstaff/deleteById",    //修改优惠列表

	// 族谱相关
	"BOOK": "/clanbook/findClanBookListByFamilyIdAndStatus", // 族谱列表
	"BOOK_ADD": "/clanbook/add", // 添加族谱
	"BOOK_EDIT": "/clanbook/update", // 更新族谱
	"BOOK_DELETE": "/clanbook/deleteById", // 删除族谱
	"BOOK_COVER": "/imp/upload/uploadFamilyImage", // 族谱封面
	"BOOK_DETAIL": "/clanbook/findById", // 族谱信息详情
	
	// 族谱相关——卷
	// todo 卷新增、编辑、删除
	"VOLUME": "/clanvolume/findVolumeListByBookId", // 族谱卷列表
	"VOLUME_IMAGE": "/clanpicture/findClanPictureListByVolumeId", //根据卷id查询图片列表
	"VOLUME_IMAGE_UPLOAD": "/imp/upload/uploadZip", //上传卷ZIP文件
	"VOLUME_DISPATCH_UPLOAD": "/clanvolume/updateStatus", // 上传卷齿录完成后更新卷信息
	"VOLUME_ADD": "/clanvolume/add", // 添加卷
	"VOLUME_EDIT": "/clanvolume/update", // 更新卷
	"VOLUME_DELETE": "/clanvolume/deleteById", // 删除卷
	
	// 族谱相关——分卷
	"VOLUME_PART": "/clanvolumepart/findByPage",
	"VOLUME_PART_ALL": "/clanvolumepart/findAll",
	"VOLUME_PART_ADD": "/clanvolumepart/add",
	"VOLUME_PART_EDIT": "/clanvolumepart/update",
	"VOLUME_PART_DELETE": "/clanvolumepart/deleteById",
	
	// 族谱相关——分支
	"SUB_CLAN": "/subclaninfo/findByPage", // 族谱卷分支列表
	"SUB_CLAN_ALL": "/subclaninfo/findAll", // 族谱卷分支列表
	"SUB_CLAN_ADD": "/subclaninfo/add", // 添加分支
	"SUB_CLAN_EDIT": "/subclaninfo/update", // 更新分支
	"SUB_CLAN_DELETE": "/subclaninfo/deleteById", // 删除分支
	
	// 族谱相关——齿录内容——cm_content（BOOK_DISPATCH相关的都是cm_content）
	"BOOK_DISPATCH": "/cmcontent/findByCmContentPage", // 根据卷id获取齿录原始内容——对应cm_content
	"BOOK_DISPATCH_UPLOAD": "/cmcontent/importExcelFileToCmContent", // 上传卷齿录excel——对应cm_content
	"BOOK_DISPATCH_ADD": "/cmcontent/addCmContent", // 新增齿录——对应cm_content
	"BOOK_DISPATCH_EDIT": "/cmcontent/updateCmContent", // 编辑齿录——对应cm_content
	"BOOK_DISPATCH_DELETE_MULTY": "/cmcontent/deleteCmContentByIdList", // 批量删除齿录——对应cm_content，idList多个
	"BOOK_DISPATCH_DELETE": "/cmcontent/deleteCmContentById", // 删除齿录——对应cm_content，id单个
	
	// 族谱相关——齿录内容——cm_content_final（BOOK_MEMBER相关的都是cm_content_final）
	"BOOK_MEMBER": "/cmcontent/findCmListByPageAndCondition", // 根据族谱id获取齿录成员信息——对应cm_content_final——谱成员列表(按照夫妻，有扩展字段)
	// "BOOK_MEMBER_DETAIL": "/cmcontent/findMainMemberListByFuzzyName", // 谱成员详情，暂时用根据谱名查节点替换，后续待使用
	"BOOK_MEMBER_DETAIL": "/cmcontent/findById", // 谱成员详情，暂时用根据谱名查节点替换，后续待使用
	"BOOK_MEMBER_DISPATCH": "/cmcontent/findByPage", // 谱成员齿录集
	"BOOK_MEMBER_ADD":"/cmcontent/add", // 新增齿录内容
	"BOOK_MEMBER_EDIT":"/cmcontent/update", // 编辑齿录内容
	"BOOK_MEMBER_DELETE":"/cmcontent/deleteById", // 删除齿录内容
	"BOOK_MEMBER_ADD_RELATION": "/cmcontent/addMemberToCmContentFinal", // 添加家人齿录内容
	
	
	"BOOK_DATA_COPY_VOLUME": "/cmcontent/copyCmContentToFinal", // 将校对完成的数据从cm_content复制到cm_content_final——按卷同步
	"BOOK_TRANS_ORAL_SIMPLE_VOLUME": "/cmcontent/setTempCopyContentAndTransport", // 繁简转化——按卷
	"BOOK_MEMBER_CONTENT_TYPE_VOLUME": "/cmcontent/updateContentTypeForCmContentToFinal", // 内容类型识别：1齿录2世代3分支4谱名——按卷
	"BOOK_MEMBER_DISPATCH_TYPE_VOLUME": "/cmcontent/batchUpdateRelativeFlagForCmContentFinal", // 姻亲识别：1亲缘/2姻缘——按卷
	"BOOK_MEMBER_ORDER_VOLUME": "/cmcontent/updateCmContentFinalOrderNo",  // 更新谱成员排序——按卷
	"BOOK_MEMBER_GENERATION_VOLUME": "/cmcontent/updateCmContentFinalGenerationNum", // 更新谱成员世代——按卷
	"BOOK_MEMBER_ANALYSIS_VOLUME": "/cmcontent/parseCmContentFinal", // 族谱final齿录集解析——按卷
	"BOOK_MEMBER_RANKING_VOLUME": "/cmcontent/batchsSetRankingForCmContentToFinal", // 族谱兄弟排序——按卷
	"BOOK_MEMBER_IDENTITY_EXTRA_VOLUME": "/cmcontent/batchUpdateExtendsCmContentFinalByVolume", // 增订识别——按卷
	"BOOK_MEMBER_ADD_EXTRA_VOLUME": "/cmcontent/batchUpdateRelationForExtendsCmContentFinal", // 自动增订关联关系——按卷
	
	"BOOK_EXTRA_LIST": "/cmcontent/findAlreadyExistCmContentFinalListByPage", // 谱成员补充齿录列表——对应cm_content_final（按照order和id正序）
	
	
	
	
	
	
	
	// 关系挂载相关
	"BOOK_ANCESTOR": "/cmcontent/findAncestorByBookId", // 获取谱始祖
	"UPDATE_FAMILY_RELATION_STATISTICS": "/familymember/setMainMemberForSonNumReal", // 家族关系统计更新
	"URL_FIND_SUB_CLAN_INFO_LIST":"/subclaninfo/findSubClanInfoListByParentId",     //查系
	"URL_FIND_SUB_CLAN_INFO_UPDATE":"/subclaninfo/update",     //更新系
	"URL_FIND_BOOK_BRANCH":"/subclaninfo/findByPage",    //谱分支分页查询
	"URL_FIND_BOOK_BRANCH_BY_FUZZYNAME":"/subclaninfo/findSubClanInfoListByFuzzyName",    //谱分支根据名字模糊查询
	"URL_FIND_BOOK_BRANCH_ADD":"/subclaninfo/add",    //谱分支新增保存
	"URL_FIND_BOOK_BRANCH_READ":"/subclaninfo/findById",    //根据ID获取谱分支记录
	"URL_FIND_BOOK_BRANCH_EDIT":"/subclaninfo/update",    //谱分支编辑保存
	"URL_FIND_BOOK_BRANCH_DELETE":"/subclaninfo/deleteById",    //根据ID删除谱分支记录
	"URL_ADD_RELATION_LIST_FOR_BOOK":"/cmcontent/addRelationListForBook",
	"URL_ADD_SPOUSE_RELATION_LIST_FOR_BOOK":"/cmcontent/addSpouseRelationListForBook", // 更新配偶关系到指定老谱
	"URL_ADD_MAIN_RELATION_LIST_FOR_BOOK":"/cmcontent/addMainRelationListForBook", // 根据父名更新父子关系到指定老谱
	"URL_CM_CONTENT_FINAL_LIST_BY_HEAD_AND_SERVERALNUM":"/cmcontent/findCmContentFinalListByHeadAndSeveralNum",
	"URL_ADD_TWO_NODES_CONNECTION_QUICKLY":"/cmcontent/addTwoNodesConnectionQuicklyByGenerationStartEnd",
	"URL_ADD_RELATION_LIST_MORE_IN_GENERATION":"/cmcontent/addRelationListMoreInGeneration",             //批量添加关系
	"URL_FIND_MEMBER_NUM_BY_GENERATION_START_END":"/cmcontent/findMemberListNumByGenerationStartEnd", // 统计各世代未挂载齿录节点数
	"URL_FIND_ANCESTOR_LIST_BY":"/cmcontent/findAncestorListBy",
	"URL_FIND_CM_CONTENT_FINAL_LINK_BYSTART":"/cmcontent/findCmContentFinalLinkByStart",
	"URL_FIND_NODE_BY_NAME":"/cmcontent/findMainMemberListByFuzzyName",     //根据名字查节点信息
	"URL_ADD_NODE_AND_RELATION":"/cmcontent/addNodeAndRelation",
	"URL_FIND_FAMILY_MEMBER":"/cmcontent/findCmContentFinalExtendsListByNum",              //老谱查寻所有成员
	"URL_FIND_MEMBER_LIST_BY_GENERATIONSTARTEND":"/cmcontent/findNumAndMemberListByGenerationStartEnd",    //按世代查询的未找到关系的成员列表和数字接口
	"URL_DELETE_MEMBER_BY_ID":"/cmcontent/deleteById",
	"URL_FIND_ANCESTOR_BY_BOOKID":"/cmcontent/findAncestorByBookId",
	"URL_FIND_RELATION_LIST_NUM_IN_BOOK":"/cmcontent/findRelationListNumInBook",
	"URL_TRANS_OLD_CHARS_TO_NEW": "/cmcontent/setTempCopyContentAndTransport", // 老谱繁简体字、异体字转换
	"URL_DELETE_CMRELATION_LIST_FROM_BOOK":"/cmcontent/deleteCmRelationListFromBook", // 重置节点关系
	"URL_FIND_SPOUSE_RELATION_LIST_NUM_IN_BOOK":"/cmcontent/findSpouseRelationListNumInBook", // 获取指定老谱待添加的配偶数
	"URL_FIND_MAIN_RELATION_LIST_NUM_IN_BOOK":"/cmcontent/findMainRelationListNumInBook", // 根据父名获取指定老谱待添加的子女数
	"URL_FIND_CM_LIST_BY_PAGE_AND_CONDITION":"/cmcontent/findCmListByPageAndCondition",
	"URL_FIND_CM_LIST_BY_FUZZY_NAME":"/cmcontent/findMemberListByHalfFuzzyName", // 根据名字模糊查询谱成员
	"UPDATE_BOOK_RELATION_STATISTICS": "/cmcontent/setTempSonNumBrotherNumAndWifeList", // 更新老谱关系统计数据
	"UPDATE_BOOK_SPOUSE": "/cmcontent/setSpouseNameForSpousers", // 更新配偶信息
	"URL_SETTING_RELATIONS_TO_SERIES": "/cmcontent/setXiForSubClanId", // 设置成员到系
	"URL_SETTING_RELATIONS_TO_BRANCH": "/cmcontent/setFangForSubClanId", // 设置成员到房
	"URL_SETTING_RELATIONS_TO_DESCENDENT": "/cmcontent/setYiForSubClanId", // 设置成员到裔
	"URL_SETTING_RELATIONS_PARENTS": "/cmcontent/updateParentIdForSubClanInfo",	// 为谱分支创建父子关系
	"URL_GET_NEW_BRANCHES_BY_BOOKID": "/subclaninfo/findSubClanInfoListByBookId",	// 根据谱ID找寻分支
	"URL_FIND_CM_CONTENT_FINAL_RELATION_TOTAL_COUNT":"/familymember/findCmContentFinalRelationTotalCount",   		//
	"URL_ADD_TOFAMILY_BY_RELATION":"/familymember/addCmContentFinalRelationToFamily",   		//同步关系到家族
	"URL_FIND_MEMBER_VISIONS_BY_MID":"/familymember/findCmContentFinalListByFmId",
	"URL_MERGE_FAMILY_MEMBER_ORIGINAL_TO_DEST":"/familymember/mergeFamilyMemberOriginalToDest",
	"URL_FIND_HOUSEHOLD_AUDITION_LIST":"/householdinaudition/findHouseholdInAuditionListBy",
	"URL_UPDATE_HM_IN_AUDITION":"/householdinaudition/updateHmInAudition",
	"URL_FIND_AUDITION_LIST_BY_FAMILY_MEMBERID":"/householdinaudition/findHouseholdInAuditionListByFamilyMemberId",
	"URL_FIND_HOUSEHOLD_MEMBER_IN_AUDITION":"/householdinaudition/findHouseholdMembersInAudition",
	"URL_HOUSEHOLD_IN_AUDITION_UPDATE":"/householdinaudition/update",
	"URL_FIND_HOUSEHOLD_PAY_IMGS":"/householdinaudition/findHouseholdPayEvidenceList",
	"URL_HOUSEHOLD_MEMBER_ENTER_FAMILY_MEMBER":"/householdinaudition/householdMemberEnterFamilyMember",     //根据待审核的家庭信息，将家庭成员进入家族成员
	"URL_CHANGE_TWO_NONDE_CONNECTION":"/cmrelation/changeTwoNodesConnection",   //更改两个节点之间的关系
	"URL_RELEASE_TWO_NONDE_CONNECTION":"/cmrelation/releaseTwoNodesConnection",   //删除两个节点之间的关系
	"URL_ADD_TWO_NONDE_CONNECTION":"/cmrelation/addTwoNodesConnection",   //添加两个节点之间的关系
	"URL_CHANGE_TWO_NONDE_CONNECTION_FAMILY":"/fmrelation/changeTwoNodesConnection",
	"URL_RELEASE_TWO_NONDE_CONNECTION_FAMILY":"/fmrelation/releaseTwoNodesConnection",
	"URL_ADD_TWO_NONDE_CONNECTION_FAMILY":"/fmrelation/addTwoNodesConnection",
	"URL_DELETE_FM_RELATION_LIST":"/fmrelation/deleteFmRelationListFromFamilyId",
	"URL_FIND_CHART_UPDATE_BY_ID":"/cmcontent/update",       //
	"URL_ADD_SUB_FAMILY_INFO":"/subfamilyinfo/addSubFamilyInfo", // 家族分支
	"URL_FIND_SUBFAMILY_BYPAGE":"/subfamilyinfo/findByPage",    //分页查询
	"URL_FIND_MAMAGER_BYNAME_AND_BYFAMILYID":"/findFamilyManagerListByNameAndFamilyId",
	"URL_FIND_VOLUME_LIST_BYID":"/clanvolume/findVolumeListByBookId",    //根据谱书id查询卷列表
	"URL_ADD_CM_CONTENT_FINAL_TO_FAMILY":"/familymember/addCmContentFinalToFamily",    //同步到家族
	// 五世一表相关
	"URL_FIND_CM_CONTENT_HAS_CHILDRENS_BY_HEADID": "/cmcontent/findAssignGenerationMemberListByHeadAndAssignGenerationNum", // 根据头结点ID和指定的世代查有后代的节点集
	
	
	
	
	
	
	
	"BOOK_MEMBER_SYNC_FAMILY_VOLUME": "/familymember/addCmContentFinalToFamily", // 同步谱成员到家族——按卷
	"BOOK_MEMBER_RELATION_SYNC_FAMILY_VOLUME": "/familymember/addCmContentFinalRelationToFamilyByVolume", // 同步谱成员关系到家族——按卷
	// 族谱权限——HMP
	"BOOK_AUTHORITY": "/openlist/findOpenListByBookId", // 族谱权限列表
	"BOOK_AUTHORITY_ADD": "/openlist/add", // 添加族谱权限
	"BOOK_AUTHORITY_EDIT": "/openlist/update", // 更新族谱权限
	"BOOK_AUTHORITY_DELETE": "/openlist/deleteById", // 删除族谱权限
	
	// 排版
	"TYPESETTING_BOOK_SUBFAMILY": "/clanvolumesubfamily/findByPage", // 卷与分支的关联关系分页——按分卷
	"TYPESETTING_BOOK_SUBFAMILY_ALL": "/clanvolumesubfamily/findAll", // 卷与分支的关联关系全部——按分卷
	"TYPESETTING_BOOK_SUBFAMILY_ADD": "/clanvolumesubfamily/add", // 添加卷与分支的关联关系
	"TYPESETTING_BOOK_SUBFAMILY_EDIT": "/clanvolumesubfamily/update", // 编辑卷与分支的关联关系
	"TYPESETTING_BOOK_SUBFAMILY_DETAIL": "/clanvolumesubfamily/findById", // 卷与分支的关联关系详情
	"TYPESETTING_BOOK_SUBFAMILY_DELETE": "/clanvolumesubfamily/deleteById", // 删除卷与分支的关联关系
	
	"TYPESETTING_BOOK_BY_HEADID": "/cmcontent/findCmContentFinalListByHeadAndAssignGenerationNum", // 根据头结点ID和指定的起止代查符合条件的后代
	
	"TYPESETTING_FAMILY_BY_HEADID1": "/familymember/findNewCmContentFinalListByHeadAndAssignGenerationNum", // 根据头结点信息查符合条件的后代——只返回displayFlag <> 2的节点
	
	"TYPESETTING_ANCESTOR_SOURCE": "/familymember/findNewSpecilFamilyMemberListByHeadAndAssignGenerationNum", // 节点集溯源到指定世代
	
	// 历次续谱相关
	"CONTINUATION": "/continuationSpectrum/findAll", // 获取家族的所有续谱记录
	// 历次续谱相关——信息采集
	"CONTINUATION_BRANCH": "/tempsubfamilyinfo/findByPage", // 临时分支列表
	"CONTINUATION_BRANCH_ALL": "/tempsubfamilyinfo/findAll", // 临时分支列表
	"CONTINUATION_BRANCH_ADD": "/tempsubfamilyinfo/add", // 添加临时分支
	"CONTINUATION_BRANCH_EDIT": "/tempsubfamilyinfo/update", // 添加临时分支
	"CONTINUATION_BRANCH_DETAIL": "/tempsubfamilyinfo/findById", // 临时分支详情
	"CONTINUATION_BRANCH_DELETE": "/tempsubfamilyinfo/deleteById", // 删除临时分支
	
	
	// 历次续谱相关——分支家庭
	"TEMPHOUSEHOLDINFO": "/temphouseholdinfo/findByPage", // 临时家庭列表
	"TEMPHOUSEHOLDINFO_ALL": "/temphouseholdinfo/findTempHouseholdInfoListByTempSubFamilyId", // 当前临时分支临时家庭所有
	"TEMPHOUSEHOLDINFO_ADD": "/temphouseholdinfo/add", // 添加临时家庭
	"TEMPHOUSEHOLDINFO_EDIT": "/temphouseholdinfo/update", // 添加临时家庭
	"TEMPHOUSEHOLDINFO_DETAIL": "/temphouseholdinfo/findById", // 临时家庭详情
	"TEMPHOUSEHOLDINFO_DELETE": "/temphouseholdinfo/deleteById", // 删除临时家庭
	"TEMPHOUSEHOLDINFO_UPLOAD_EXCEL": "/temphouseholdinfo/importExcelFileToTempHouseholdInfo", // 批量导入临时家庭信息Excel
	// 历次续谱相关——分支家庭——家庭图片
	"TEMPHOUSEHOLD_IMAGE": "/temphouseholdpicture/findTempHouseholdPictureListByHouseholdId", // 查看临时家庭图片列表
	"TEMPHOUSEHOLD_IMAGE_UPLOAD": "/imp/upload/uploadZipForTempHousehold", // 批量导入临时家庭图片
	// 历次续谱相关——分支家庭——家庭成员
	"TEMPHOUSEHOLDMEMBER": "/temphouseholdmember/findByPage", // 获取临时家庭成员列表
	"TEMPHOUSEHOLDMEMBER_EXCEL_UPLOAD": "/temphouseholdmember/importExcelFileToTempHouseholdMember", // 批量导入临时家庭成员信息Excel
	"TEMPHOUSEHOLDMEMBER_ADD": "/temphouseholdmember/add", // 添加临时家庭成员
	"TEMPHOUSEHOLDMEMBER_EDIT": "/temphouseholdmember/update", // 编辑临时家庭成员
	"TEMPHOUSEHOLDMEMBER_DETAIL": "/temphouseholdmember/findById", // 临时家庭成员详情
	"TEMPHOUSEHOLDMEMBER_DELETE": "/temphouseholdmember/deleteById", // 删除临时家庭成员
	// 历次续谱相关——分支家庭数据流程化处理
	"CONTINUATION_DATA_COPY_HOUSEHOLD": "/temphouseholdmember/copyCmContentToFinal", // 将校对完成的数据从cm_content复制到cm_content_final——按分支家庭
	"CONTINUATION_TRANS_ORAL_SIMPLE_HOUSEHOLD": "/temphouseholdmember/setTempCopyContentAndTransport", // 繁简转化——按分支家庭
	"CONTINUATION_MEMBER_DISPATCH_TYPE_HOUSEHOLD": "/temphouseholdmember/batchUpdateRelativeFlagForCmContentFinal", // 姻亲识别：1亲缘/2姻缘——按分支家庭
	"CONTINUATION_MEMBER_ORDER_HOUSEHOLD": "/temphouseholdmember/updateCmContentFinalOrderNo",  // 更新临时家庭成员排序——按分支家庭
	// "CONTINUATION_GENERATION_HOUSEHOLD": "/temphouseholdmember/updateCmContentFinalGenerationNum", // 更新谱成员世代——按分支家庭——不需要
	"CONTINUATION_MEMBER_ANALYSIS_HOUSEHOLD": "/temphouseholdmember/parseCmContentFinal", // 族谱final齿录集解析——按分支家庭
	"CONTINUATION_MEMBER_RANKING_HOUSEHOLD": "/temphouseholdmember/batchsSetRankingForCmContentToFinal", // 临时家庭兄弟排序——按分支家庭
	"CONTINUATION_MEMBER_WAITING_HEAD_NODE": "/temphouseholdmember/batchIdentifyTempHouseholdMemberHeads", // 待续谱头结点识别并关联
	"CONTINUATION_MEMBER_WAITING_RELATION_NODES": "/temphouseholdmember/batchIdentifyAllTempHouseholdMemberByHeads", // 识别待续谱关系成员
	"CONTINUATION_MEMBER_IDENTITY_EXTRA_HOUSEHOLD": "/temphouseholdmember/batchUpdateExtendsCmContentFinalByVolume", // 已上谱识别——按分支家庭
	"CONTINUATION_MEMBER_ADD_EXTRA_HOUSEHOLD": "/temphouseholdmember/batchUpdateRelationForExtendsCmContentFinal", // 自动已上谱关联关系——按分支家庭
	"CONTINUATION_MEMBER_IDENTITY_EXTRA_BY_FAMILY": "/temphouseholdmember/batchUpdateMoreRelationForExtendsCmContentFinal", // 反向识别已上谱临时家庭成员
	
	// 历次续谱相关——分支家庭——家庭成员——定稿后final表
	"TEMPHOUSEHOLDMEMBERFINAL": "/temphouseholdmember/findTempHouseholdMemberFinalByPage", // 获取临时家庭成员列表
	"TEMPHOUSEHOLDMEMBERFINAL_EDIT": "/temphouseholdmember/updateTempHouseholdMemberFinal", // 编辑临时家庭成员
	"TEMPHOUSEHOLDMEMBERFINAL_DETAIL": "/temphouseholdmember/findTempHouseholdMemberFinalById", // 临时家庭成员详情
	// 临时家庭分支挂载
	"TEMPHOUSEHOLD_MEMBERS_TREE":"/temphouseholdmember/findCmContentFinalListByHeadAndSeveralNum", // 获取临时家庭树
	"TEMPHOUSEHOLD_MOUNT_SPOUSE": "/temphouseholdmember/addSpouseRelationListForBook", // 更新配偶关系到指定老谱
	"TEMPHOUSEHOLD_MOUNT_FATHER": "/temphouseholdmember/addMainRelationListForBook", // 根据父名更新父子关系到指定老谱
	"TEMPHOUSEHOLD_MOUNT_UPDATE": "/temphouseholdmember/setTempSonNumBrotherNumAndWifeList", // 更新老谱关系统计数据
	"TEMPHOUSEHOLD_MOUNT_UPDATE_SPOUSE": "/temphouseholdmember/setSpouseNameForSpousers", // 为配偶更新主家名称
	// 历次续谱相关——分支家庭——已上谱家庭成员
	"HOUSEHOLD_EXTRA_LIST": "/temphouseholdmember/findAlreadyExistCmContentFinalListByPage", // 已上谱家庭成员处理——废弃
	"HOUSEHOLD_MEMBER_LIST": "/temphouseholdmember/findTempHouseholdMemberFinalByPage",
	"TEMPHOUSEHOLD_UNRELATED_ALL": "/temphouseholdmember/findUnRelatedMemberListByBookId", // 当前临时家庭未挂载关系成员集合（可以是整个家族、整个分支、单个家庭）
	
	"TEMPHOUSEHOLD_MOUNT_UPDATE_GENERATION": "", // 为已挂载关系的临时家庭成员更新世代
	
	"TEMPHOUSEHOLD_MEMBER_N_GENERATION_TREE": "/temphouseholdmember/findNGenerationListBySpecialId", // 获取临时家庭指定头结点上下N代
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	// 家族相关
	"FAMILY_MEMBER": "/familymember/findByPage", // 家族成员列表分页——按分支
	"FAMILY_MEMBER_ADD": "/familymember/add", // 添加家族成员
	"FAMILY_MEMBER_EDIT": "/familymember/update", // 编辑家族成员
	"FAMILY_MEMBER_DETAIL": "/familymember/findById", // 家族成员详情
	"FAMILY_MEMBER_DELETE": "/familymember/deleteById", // 删除家族成员
	"FAMILY_MEMBER_ORDER": "/familymember/updateFamilyMemberOrderNo", // 更新家族成员排序
	
	"FAMILY_MEMBER_SYNC_TEMPHOUSEHOLD": "/familymember/addTempHouseholdMemberFinalToFamily", // 同步临时家庭成员到家族
	"FAMILY_MEMBER_SYNC_TEMPHOUSEHOLD_RELATION": "/familymember/addTempHouseholdMemberFinalRelationToFamily", // 同步临时家庭成员关系到家族
	
	"FAMILY_MEMBER_EXPORT_BY_HEADID": "/familymember/exportFamilyMemberListByHeadAndAssignGenerationNum", // 导出齿录集
	"FAMILY_MEMBER_EXCEL_UPLOAD": "/familymember/importExcelFileToFamilyMember", // 导入齿录集：1修改2新增3删除
	
	"FAMILY_MEMBER_TREE": "/familymember/findFamilyMemberListByHeadAndSeveralNum", // 获取家族指定头结点名下N代家族世系树——返回所有节点
	"FAMILY_MEMBER_N_GENERATION_TREE": "/familymember/findNGenerationListBySpecialId", // 获取家族指定头结点上下N代家族世系树
	
	"FAMILY_MEMBER_ANCENSTORS": "/familymember/findAncenstorInFamilyMembers", // 获取家族始祖列表
	"FAMILY_MEMBER_PRINT_TREE": "/familymember/findSpecilFamilyMemberListByHeadAndAssignGenerationNum", // 根据筛选条件展示familyMember五世一表——只返回displayFlag <> 3的节点
	
	"FAMILY_SUBTREE_PARENT": "/subfamilyinfo/batchUpdateParentIdByFamilyId", // 批量更新家族分支所属父级
}

export default API_KEY
